<template>
  <div>
    <a-modal v-model="visible" title="已分配详情" :destroyOnClose="true" :footer="null" @cancel="$emit('cancel', false)">
      <a-table rowKey="id" size="small" :columns="columns" :data-source="items" :pagination="false" />
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ["visible", "items"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "拣货单号",
          dataIndex: "picking_order_number",
        },
        {
          title: "分配数量",
          dataIndex: "remain_quantity",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped></style>
